<template>
  <div>
    <vs-popup :active.sync="is2FaPopupActive" title="Segundo Factor de Autenticación">
      <div class="p-4 pt-0">
        <div>
          <h6 v-if="!validating && !customIndicationMessage" class="title-dark">Por favor ingresa el código de 6 dígitos.</h6>
          <h6 v-if="!validating && customIndicationMessage" class="title-dark">{{customIndicationMessage}}</h6>
          <h6 v-if="validating" class="title-dark">Validando...</h6>

          <vs-alert v-if="errorMssg" color="danger" class="mt-3 mb-3" style="height: auto">{{ errorMssg }}
            <a v-if="!customErrorMessage" :href="$sanitizeUrl(twoFactorCodeUrl)" target="_blank" rel="noopener noreferrer" class="underline bold black">Aquí te explicamos rápidamente como resolverlo.</a>
          </vs-alert>
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <div class="flex items-center flex-grow justify-left mt-1">
                <vs-input
                  type="text"
                  v-model.lazy="code"
                  name="code"
                  v-validate="'required|numeric'"
                  maxlength="6"
                  autocomplete="off"
                  :disabled="validating"
                  v-on:keyup.enter="sendCode"
                  placeholder="Ingresa el código" />
                <vs-button :id="buttonId" :data-projectid="currentProjectId" class="statement-btn ml-6" color="primary" :disabled="validating" @click="sendCode" >Enviar</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: "TwoFactorAuth",
  props: {
    userId: {
      type: [String,Number],
      required: true
    },
    customIndicationMessage: {
      type: String,
      required: false,
      default: null
    },
    customErrorMessage: {
      type: String,
      required: false,
      default: null
    },
    currentProjectId: {
      type: [String,Number],
      required: false,
      default: null
    },
    buttonId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      code: null,
      errorMssg: null,
      validating: false,
      twoFactorCodeUrl: "https://soporte.redgirasol.com/support/solutions/articles/48001243819-el-c%C3%B3digo-de-mi-segundo-factor-de-autenticaci%C3%B3n-es-incorrecto-qu%C3%A9-puedo-hacer-"
    };
  },
  watch: {
    is2FaPopupActive(n){
      if(n){
        this.validating = false;
        this.errorMssg = null;
        this.code = null;
      }
    }
  },
  computed: {
    is2FaPopupActive: {
      get() {
        return this.$store.state.is2faPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", val);
      }
    },
  },
  methods: {
    async sendCode(){
      this.errorMssg = null;
      if(this.code !== null){
        if(this.code.length === 6){
          const newCode = this.code;
          this.code = null;
          await this.$emit('on-code-sent', newCode);
          await this.prevalidate2Fa(newCode);
          return;
        }
      }
      this.errorMssg = "Ingrese un código válido de 6 dígitos.";
    },

    async prevalidate2Fa(newCode){
      this.showLoading(true)
      this.validating = true
      try {
        let payload = {
          "2fa_restart_cookie": true,
          "code": newCode,
        }
        await axios.post(`/api/v1/userInfo/${this.userId}/prevalidate2fa`, payload);
        this.is2FaPopupActive = false;
        this.$emit('on-code-validated', newCode);
      }
      catch (e) {
        //this.is2FaPopupActive = false;
        //this.errorNotifDialog("Segundo Factor de Autenticación", "El código ingresado es inválido, por favor revisa los datos e intenta de nuevo.");
        this.validating = false
        this.errorMssg = this.customErrorMessage ?? "El código ingresado es inválido. Es posible que tu aplicación de autenticación no tenga los tiempos sincronizados.";
        this.$emit('on-code-invalid', newCode);
      }
      this.showLoading(false)
    },
  },
};
</script>